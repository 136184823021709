<template>
  <div class="import-transactions__step-wrap">
    <div
      class="import-transactions__step"
    >
      <h2><slot name="header" /></h2>
      <slot name="description" />

      <cv-form @submit="submit($event)">
        <cv-select
          label="Column name"
          v-model="selectedColumn"
          v-focus
        >
          <cv-select-option
            disabled
            value=""
            selected
            hidden
          >
            Choose an option
          </cv-select-option>
          <cv-select-option
            :value="column"
            v-for="(column) in columns"
            :key="column"
          >
            {{ column }}
          </cv-select-option>
        </cv-select>

        <cv-button
          :disabled="!canSubmit"
        >
          Next
        </cv-button>
      </cv-form>
    </div>
    <cv-data-table
      v-if="dataPreview.length"
      :columns="['From sheet']"
      :data="dataPreview"
      class="import-transactions__data-preview-table"
      ref="table"
    />
  </div>
</template>

<script>
// Action, ID, Date, Ticker, Quantity, Price per share, exchange rate,
import {
  CvForm,
  CvSelect,
  CvSelectOption,
  CvButton,
  CvDataTable,
} from '@carbon/vue';
import uniqBy from 'lodash/uniqBy';

export default {
  name: 'ChooseColumn',

  components: {
    CvForm,
    CvButton,
    CvSelect,
    CvSelectOption,
    CvDataTable,
  },

  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedColumn: null,
    };
  },

  computed: {
    canSubmit() {
      return this.isValidColumnSelected;
    },

    isValidColumnSelected() {
      return this.columns.includes(this.selectedColumn);
    },

    dataPreview() {
      if (!this.isValidColumnSelected) return [];
      return uniqBy(
        this.data,
        this.selectedColumn,
      ).map((item) => [item[this.selectedColumn]]).slice(0, 20);
    },
  },

  methods: {
    submit(event) {
      event.preventDefault();
      this.$emit('input', this.selectedColumn);
      this.$emit('submit', this.selectedColumn);
    },

  },
};
</script>
