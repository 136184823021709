<template>
  <div class="import-transactions__step-wrap">
    <div
      class="import-transactions__step"
    >
      <h2>Choose the base currency of the broker.</h2>

      <cv-form @submit="submit($event)">
        <cv-select
          label=""
          v-model="selectedCurrency"
        >
          <cv-select-option
            disabled
            value=""
            selected
            hidden
          >
            Choose an option
          </cv-select-option>
          <cv-select-option
            :value="currency"
            v-for="(currency) in currencies"
            :key="currency"
          >
            {{ currency }}
          </cv-select-option>
        </cv-select>
        <cv-button
          :disabled="!canSubmit"
        >
          Next
        </cv-button>
      </cv-form>
    </div>
  </div>
</template>

<script>
// Action, ID, Date, Ticker, Quantity, Price per share, exchange rate,
import {
  CvForm,
  CvSelect,
  CvSelectOption,
  CvButton,

} from '@carbon/vue';

export default {
  name: 'BaseCurrency',

  components: {
    CvForm,
    CvButton,
    CvSelect,
    CvSelectOption,

  },

  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    currencies: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedCurrency: null,
    };
  },

  computed: {
    canSubmit() {
      return this.currencies.indexOf(this.selectedCurrency) !== -1;
    },
  },

  methods: {
    submit(event) {
      event.preventDefault();
      this.$emit('input', this.selectedCurrency);
      this.$emit('submit', this.selectedCurrency);
    },

    onAddPresetClick() {
      this.$emit('add', this.selectedCurrency);
    },

  },
};
</script>
