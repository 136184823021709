<template>
  <div class="import-transactions__step-wrap">
    <div
      class="import-transactions__step"
    >
      <h2>Summary &amp; save</h2>
      <p>
        You are about to import <strong>{{ newTransactions.length }}
          transactions</strong> and <strong>{{ assets.length }} assets</strong>.
      </p>

      <cv-button-set>
        <cv-button
          :disabled="!canSubmit"
          @click="submit"
        >
          Save
        </cv-button>
      </cv-button-set>
      <cv-inline-loading
        v-if="loadingState && loadingState !== 'error'"
        :state="loadingState"
        loading-text="Loading..."
      />
    </div>
  </div>
</template>

<script>
import {
  CvButton,
  CvButtonSet,
  CvInlineLoading,
} from '@carbon/vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import mapSeries from 'promise-map-series';
import * as mappings from '../importUtils';
import { addAssets, addTrasactions } from '../../../lib/api';

export default {
  name: 'SummaryAndSave',

  components: {
    CvButton,
    CvButtonSet,
    CvInlineLoading,
  },

  props: {
    preset: {
      type: Object,
      default: () => {},
      required: true,
    },

    sheet: {
      type: Array,
      default: () => [],
      required: true,
    },

    assets: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      loadingState: '',
    };
  },

  computed: {
    ...mapGetters({
      getAssetByIsin: 'assets/getByIsin',
      getTransactionByImportId: 'transactions/getByImportId',
    }),

    canSubmit() {
      return !this.isLoading;
    },

    transactions() {
      return this.sheet.filter((item) => mappings.getType(item, this.preset) !== '')
        .map((item) => ({
          type: mappings.getType(item, this.preset),
          quantity: mappings.getQuantity(item, this.preset),
          price: mappings.getPrice(item, this.preset),
          exchangeRate: mappings.getExchangeRate(item, this.preset),
          datetime: mappings.getDate(item, this.preset),
          importId: mappings.getImportId(item, this.preset),
          isin: mappings.getAssetId(item, this.preset),
        }));
    },

    newTransactions() {
      return this.transactions.filter(({ importId }) => !this.getTransactionByImportId(importId));
    },
  },

  methods: {
    async submit() {
      this.loadingState = 'loading';
      try {
        if (this.assets.length) {
          const assets = await addAssets(this.assets);
          this.$store.dispatch('assets/add', assets);
        }

        const transactionsData = this.newTransactions.map((item) => ({
          ...item,
          assetId: this.getAssetByIsin(item.isin).id,
        }));

        const transactions = (await mapSeries(
          _.chunk(transactionsData, 50), (chunk) => addTrasactions(chunk),
        )).flat();

        this.$store.dispatch('transactions/add', transactions);
        this.$toasts.success('Import successful');
        this.$emit('submit');
        this.loadingState = 'loaded';
      } catch (error) {
        this.loadingState = 'error';
        this.$toasts.error('Failed to import', { subTitle: error.message });
      } finally {
        this.isLoading = false;
      }
    },

  },
};
</script>
