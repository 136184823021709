<template>
  <div class="import-transactions__step-wrap">
    <div
      class="import-transactions__step"
    >
      <h2>Select preset format</h2>
      <p>
        Here you can choose or create a <strong>preset format</strong> for the
        tranasction spreadsheet you uploaded. If you create one it will be available
        here the next time you come back.
      </p>

      <cv-form @submit="submit($event)">
        <cv-button-set>
          <cv-select
            label=""
            v-model="selectedPreset"
            v-focus
          >
            <cv-select-option
              disabled
              value=""
              selected
              hidden
            >
              Choose an option
            </cv-select-option>
            <cv-select-option
              :value="preset.id"
              v-for="(preset) in presets"
              :key="preset.id"
            >
              {{ preset.name }}
            </cv-select-option>
          </cv-select>
          <cv-icon-button
            kind="secondary"
            size="field"
            label="Add preset"
            tip-position="right"
            :icon="Add20"
            class="preset--add-button"
            @click="onAddPresetClick"
          />
        </cv-button-set>
        <cv-button-set>
          <cv-button
            :disabled="!canSubmit"
          >
            Next
          </cv-button>
        </cv-button-set>
      </cv-form>
    </div>
  </div>
</template>

<script>
// Action, ID, Date, Ticker, Quantity, Price per share, exchange rate,
import {
  CvForm,
  CvSelect,
  CvSelectOption,
  CvButton,
  CvIconButton,
  CvButtonSet,
} from '@carbon/vue';
import Add20 from '@carbon/icons-vue/es/add/20';

export default {
  name: 'SelectPreset',

  components: {
    CvForm,
    CvButton,
    CvSelect,
    CvSelectOption,
    CvIconButton,
    CvButtonSet,
  },

  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    presets: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedPreset: this.value,
      Add20,
    };
  },

  computed: {
    canSubmit() {
      return !!this.presets.find(({ id }) => this.selectedPreset === id);
    },
  },

  methods: {
    submit(event) {
      event.preventDefault();
      this.$emit('input', this.selectedPreset);
      this.$emit('submit', this.selectedPreset);
    },

    onAddPresetClick() {
      this.$emit('add');
    },

  },
};
</script>
