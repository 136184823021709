<template>
  <div>
    <div class="asset-info__table-wrap">
      <cv-data-table-skeleton
        v-if="isLoading"
        :columns="columns"
        :rows="this.assetIds.length >= 15 ? 15 : this.assetIds.length"
        title="Asset details"
        helper-text="Enter detail for assets that couldn't be found and ensure the rest are correct"
      />
      <cv-data-table
        v-if="!isLoading"
        title="Asset details"
        helper-text="Enter detail for assets that couldn't be found and ensure the rest are correct"
        :columns="columns"
        :data="tableData"
        :pagination="{ numberOfItems: this.assetData.length, pageSizes: [15, 50, 100] }"
        @pagination="onPagination"
        :zebra="false"
        :sortable="true"
        :overflow-menu="[]"
        @overflow-menu-click="onOverflowMenuClick"
        @sort="onSort"
        ref="table"
      >
        <template
          slot="data"
        >
          <cv-data-table-row
            v-for="(item, rowIndex) in tableData"
            :key="`${rowIndex}`"
            :value="`${rowIndex}`"
          >
            <cv-data-table-cell>
              <cv-tag
                :kind="statusToKind(item.status)"
                :label="item.status"
              />
            </cv-data-table-cell>
            <cv-data-table-cell>
              {{ item.isin }}
            </cv-data-table-cell>
            <cv-data-table-cell>
              {{ item.exchange }}
            </cv-data-table-cell>
            <cv-data-table-cell>
              {{ item.symbol }}
            </cv-data-table-cell>
            <cv-data-table-cell>
              {{ item.name }}
            </cv-data-table-cell>
            <cv-data-table-cell>
              <cv-icon-button
                kind="ghost"
                size="sm"
                label="Edit"
                tip-position="left"
                :icon="Edit20"
                @click="showDetailEditor(item)"
                v-if="item.status !== 'existing'"
              />
            </cv-data-table-cell>
          </cv-data-table-row>
        </template>
      </cv-data-table>
    </div>
    <cv-button
      :disabled="canSubmit"
      @click="submit"
    >
      Next
    </cv-button>
    <asset-editor
      ref="assetEditor"
      :value="currentlyEditingAsset"
    />
  </div>
</template>

<script>

import {
  CvDataTable,
  CvDataTableCell,
  CvDataTableRow,
  CvTag,
  CvDataTableSkeleton,
  CvIconButton,
  CvButton,
} from '@carbon/vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import Edit20 from '@carbon/icons-vue/es/edit/20';
import AssetEditor from '../../../components/AssetEditor.vue';

export default {
  name: 'AssetsTable',

  components: {
    CvDataTable,
    CvDataTableCell,
    CvDataTableRow,
    CvTag,
    CvDataTableSkeleton,
    CvIconButton,
    AssetEditor,
    CvButton,
  },

  props: {
    assetIds: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      Edit20,
      isLoading: false,
      modalShown: false,
      assetData: [],
      columns: [
        'Status',
        'ID',
        'Exchange',
        'Symbol',
        'Name',
        '',
      ],
      pageStart: 1,
      pageNumber: 1,
      pageLength: 15,
      sortIndex: 0,
      sortOrder: 'none',
      currentlyEditingAsset: {},
    };
  },

  computed: {
    ...mapGetters({
      existingAssets: 'assets/getAll',
    }),

    existingAssetsMap() {
      return this.existingAssets.reduce((acc, item) => {
        acc[item.isin] = item;
        return acc;
      }, {});
    },

    newAssets() {
      return this.assetData
        .filter(({ status }) => (status !== 'existing'))
        .map(({
          isin, exchange, name, symbol,
        }) => ({
          isin, exchange, name, symbol,
        }));
    },

    canSubmit() {
      return !!this.assetData.filter(({ status }) => (status === 'invalid')).length;
    },

    tableData() {
      return [...this.assetData]
        .sort((a, b) => {
          if (a.status === 'invalid' && b.status !== 'invalid') {
            return -1;
          }
          if (a.status === 'unsure' && b.status !== 'unsure') {
            return -1;
          }
          if (a.status === 'valid' && b.status !== 'valid') {
            return -1;
          }
          if (a.status === 'existing' && b.status !== 'existing') {
            return -1;
          }
          return 1;
        })
        .slice(this.pageStart - 1, this.pageStart + this.pageLength - 1);
    },
  },

  methods: {
    onPagination({ start, page, length }) {
      this.pageNumber = page;
      this.pageStart = start;
      this.pageLength = length;
    },

    onSort({ index, order }) {
      this.sortIndex = index;
      this.sortOrder = order;
    },

    onOverflowMenuClick({ rowValue, menuIndex }) {
      if (menuIndex === 0) {
        this.$refs.editModal.show(this.filteredData[rowValue][0]);
      }
    },

    async searchForAsset(id) {
      const { data } = await axios.get(`https://stock-data-sigma.vercel.app/api?module=search&query=${id}`);

      return data.quotes[0];
    },

    showDetailEditor(asset) {
      this.currentlyEditingAsset = asset;
      this.$refs.assetEditor.show();
    },

    submit(event) {
      event.preventDefault();
      this.$emit('input', this.newAssets);
      this.$emit('submit');
    },

    statusToKind(status) {
      const map = {
        existing: 'blue',
        invalid: 'red',
        unsure: 'magenta',
        valid: 'green',
      };

      return map[status];
    },
  },

  async mounted() {
    this.isLoading = true;
    this.assetData = (await Promise.allSettled(
      this.assetIds.map(this.searchForAsset),
    ))
      .map(({ value = {} }, index) => {
        const isin = this.assetIds[index];
        const existing = this.existingAssetsMap[isin] || {};
        // eslint-disable-next-line no-nested-ternary
        const status = existing.symbol ? 'existing' : !value.symbol ? 'invalid' : value.score < 25000 ? 'unsure' : 'valid';
        // eslint-disable-next-line no-nested-ternary
        const kind = existing.symbol ? 'blue' : !value.symbol ? 'red' : value.score < 25000 ? 'magenta' : 'green';
        return {
          isin,
          exchange: value.exchDisp || existing.exchange || '',
          name: value.longname || existing.name || '',
          symbol: value.symbol || existing.symbol || '',
          status,
          kind,
        };
      });
    this.isLoading = false;
  },
};
</script>

<style>
.asset-info__table-wrap {
  margin-bottom: 2rem;
}

.assets-table td, .assets-table tr {
  max-width: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;

}

.assets-table td:nth-of-type(3) {
  width: auto;
}

.assets-table .bx--table-column-menu {
  width: 4rem;
}
</style>
