<template>
  <div>
    <div class="content-header">
      <h1>Import transactions</h1>
    </div>

    <select-file
      v-if="currentStep === steps.SELECT_FILE"
      v-model="sheetData"
      @submit="nextStep(steps.SELECT_PRESET)"
    />

    <select-preset
      v-if="currentStep === steps.SELECT_PRESET"
      v-model="selectedPresetId"
      :presets="presets"
      @submit="nextStep(steps.ASSET_INFO)"
      @add="nextStep(steps.CREATE_PRESET)"
    />

    <create-preset
      v-if="currentStep === steps.CREATE_PRESET"
      :sheet-data="sheetData"
      v-model="selectedPresetId"
      @submit="nextStep(steps.SELECT_PRESET)"
    />

    <asset-info
      v-if="currentStep === steps.ASSET_INFO"
      :asset-ids="assetIds"
      v-model="assetData"
      @submit="nextStep(steps.SUMMARY_AND_SAVE)"
    />

    <summary-and-save
      v-if="currentStep === steps.SUMMARY_AND_SAVE"
      :assets="assetData"
      :preset="selectedPreset"
      :sheet="sheetData"
      @submit="done"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Add20 from '@carbon/icons-vue/es/add/20';
import _ from 'lodash';
import * as steps from './stepNames';
import SelectFile from './Steps/SelectFile.vue';
import SelectPreset from './Steps/SelectPreset.vue';
import AssetInfo from './Steps/AssetInfo.vue';
import CreatePreset from './Steps/Preset/CreatePreset.vue';
import SummaryAndSave from './Steps/SummaryAndSave.vue';

export default {
  name: 'ImportTransactions',

  components: {
    SelectFile,
    SummaryAndSave,
    SelectPreset,
    AssetInfo,
    CreatePreset,
  },

  data() {
    return {
      currentStep: steps.SELECT_FILE,
      selectedPresetId: null,
      sheetData: [],
      assetData: [],
      steps,
      Add20,
    };
  },

  computed: {
    ...mapGetters({
      presets: 'importPresets/getAll',
      getPreset: 'importPresets/get',
    }),

    selectedPreset() {
      return this.getPreset(this.selectedPresetId);
    },

    assetIds() {
      return _.uniq(this.sheetData
        .map((item) => item[this.selectedPreset.assetIdColumn])
        .filter((item) => !!item));
    },
  },

  methods: {
    nextStep(step) {
      this.currentStep = step;
    },

    done() {
      this.$store.dispatch('ledger/calculate');
      this.$router.push('/transactions');
    },
  },
};
</script>

<style lang="scss">
  .import-transactions__step-wrap {
    display: flex;
  }

  .import-transactions__step {
    display: flex;
    flex-direction: column;
    width: 30rem;
    margin-right: 3rem;
    align-items: flex-start;

    h2 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .bx--form > * {
      margin-bottom: 2rem;
    }
  }

  .preset--add-button {
    flex: 0;
    align-self: flex-end;
  }

  .import-transactions__data-preview-table {
    max-width: 30rem;
  }
</style>
