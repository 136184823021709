<template>
  <cv-modal
    ref="modal"
    :auto-hide-off="false"
    :primary-button-disabled="isSubmitDisabled"
    @primary-click="saved"
  >
    <template
      slot="title"
      v-if="value"
    >
      Add detail for wa {{ value.id }}
    </template>
    <template
      slot="content"
      v-if="value"
    >
      <cv-text-input
        class="asset-editor__input"
        label="Exchange"
        v-model="value.exchange"
      />

      <cv-text-input
        class="asset-editor__input"
        label="Symbol"
        v-model="value.symbol"
      />

      <cv-text-input
        class="asset-editor__input"
        label="Name"
        v-model="value.name"
      />
    </template>
    <template slot="primary-button">
      Save
    </template>
  </cv-modal>
</template>

<script>
import {
  CvTextInput,
  CvModal,
} from '@carbon/vue';

export default {
  name: 'AssetEditor',
  components: {
    CvTextInput,
    CvModal,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isSubmitDisabled() {
      return !this.value.exchange
      || this.value.exchange.trim() === ''
      || this.value.name.trim() === ''
      || this.value.symbol.trim() === '';
    },
  },

  methods: {
    show() {
      this.$refs.modal.show();
    },

    saved() {
      this.value.status = 'edited';
      this.value.kind = 'teal';
      this.$refs.modal.hide();
    },
  },
};
</script>

<style>
.asset-editor__input {
  margin: 0 0 2rem 0;
}

.asset-editor__input:last-child {
  margin: 0;
}
</style>
