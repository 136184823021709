export const TRANSACTION_TYPE = 'TRANSACTION_TYPE';
export const FILTER_PURCHASE = 'FILTER_PURCHASE';
export const FILTER_SALE = 'FILTER_SALE';
export const TRANSACTION_DATE = 'TRANSACTION_DATE';
export const TRANSACTION_ID = 'TRANSACTION_ID';
export const TRANSACTION_ASSET = 'TRANSACTION_ASSET';
export const TRANSACTION_PPS = 'TRANSACTION_PPS';
export const TRANSACTION_QUANTITY = 'TRANSACTION_QUANTITY';
export const TRANSACTION_EXCHANGE_RATE = 'TRANSACTION_EXCHANGE_RATE';
export const BASE_CURRENCY = 'BASE_CURRENCY';
export const PRESET_NAME = 'PRESET_NAME';
export const SELECT_PRESET = 'SELECT_PRESET';
