<template>
  <div class="import-transactions__step-wrap">
    <div
      class="import-transactions__step"
    >
      <h2>Give this preset a name</h2>

      <cv-form @submit.prevent="submit">
        <cv-text-input
          label=""
          v-model="presetName"
          v-focus
        />
        <cv-button
          :disabled="!canSubmit"
        >
          Save
        </cv-button>
      </cv-form>
    </div>
  </div>
</template>

<script>
import {
  CvForm,
  CvButton,
  CvTextInput,

} from '@carbon/vue';
import { addImportPreset } from '../../../../lib/api';

export default {
  name: 'SavePreset',

  components: {
    CvForm,
    CvButton,
    CvTextInput,
  },

  props: {
    presetData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      presetName: null,
    };
  },

  computed: {
    canSubmit() {
      return typeof this.presetName !== 'undefined' && this.presetName !== '';
    },
  },

  methods: {
    async submit(event) {
      event.preventDefault();
      this.isLoading = true;
      try {
        const preset = await addImportPreset({
          ...this.presetData,
          name: this.presetName,
        });

        this.$store.dispatch('importPresets/add', [preset]);

        this.$emit('submit', preset.id);
        this.$toasts.success('Added preset');
      } catch (error) {
        this.$toasts.error('Failed to save preset', { subTitle: error.message });
      } finally {
        this.isLoading = false;
      }
    },

  },
};
</script>
