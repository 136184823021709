import hash from 'object-hash';
import anyDateParser from 'any-date-parser';

export const getType = (item, {
  transactionColumn,
  purchaseFilter,
  saleFilter,
}) => {
  const column = item[transactionColumn].toLowerCase();
  if (column.includes(saleFilter.toLowerCase())) return 'SELL';
  if (column.includes(purchaseFilter.toLowerCase())) return 'BUY';
  return '';
};

export const getQuantity = (item, { quantityColumn }) => item[quantityColumn];
export const getExchangeRate = (item, { exchangeRateColumn }) => item[exchangeRateColumn];
export const getPrice = (item, { pricePerShareColumn }) => item[pricePerShareColumn];
export const getDate = (item, { dateColumn }) => anyDateParser.fromString(item[dateColumn]);
export const getImportId = (item, preset) => hash(`${item[preset.idColumn]}-${item[preset.dateColumn]}`);
export const getAssetId = (item, { assetIdColumn }) => item[assetIdColumn];
