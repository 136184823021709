<template>
  <div class="import-transactions__step-wrap">
    <div
      class="import-transactions__step"
    >
      <h2>Filter asset sale</h2>
      <p>
        Type text that will match only asset sale records.
        You can see the matches in the table below.
      </p>

      <cv-form @submit="submit($event)">
        <cv-text-input
          label="Text to search"
          v-model="saleFilter"
        />
        <cv-button>Next</cv-button>
      </cv-form>
    </div>
    <cv-data-table
      v-if="dataPreview.length"
      :columns="['From sheet']"
      :data="dataPreview"
      class="import-transactions__data-preview-table"
      ref="table"
    />
  </div>
</template>

<script>
// Action, ID, Date, Ticker, Quantity, Price per share, exchange rate,
import {
  CvForm,
  CvTextInput,
  CvButton,
  CvDataTable,
} from '@carbon/vue';
import uniqBy from 'lodash/uniqBy';

export default {
  name: 'AssetSale',

  components: {
    CvForm,
    CvTextInput,
    CvButton,
    CvDataTable,
  },

  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    transactionColumn: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      saleFilter: '',
    };
  },

  computed: {

    dataPreview() {
      let result = this.data;
      if (this.saleFilter !== '') {
        result = this.data.filter((item) => item[this.transactionColumn]
          .toString()
          .toLowerCase()
          .includes(this.saleFilter));
      }

      return uniqBy(result, this.transactionColumn)
        .map((item) => [item[this.transactionColumn]]).slice(0, 20);
    },
  },

  methods: {
    submit(event) {
      event.preventDefault();
      this.$emit('input', this.saleFilter);
      this.$emit('submit', this.saleFilter);
    },
  },

  mounted() {

  },
};
</script>
