<template>
  <div>
    <choose-column
      v-if="currentStep === steps.TRANSACTION_TYPE"
      :data="sheetData"
      :columns="columns"
      v-model="transactionColumn"
      :key="steps.TRANSACTION_TYPE"
      @submit="nextStep(steps.FILTER_PURCHASE)"
    >
      <template v-slot:header>
        Choose transaction type column
      </template>

      <template v-slot:description>
        <p>
          Pick the column containing information to determine
          whether a row is regarding a buy or sell transaction.
        </p>
      </template>
    </choose-column>

    <asset-purchase
      v-if="currentStep === steps.FILTER_PURCHASE"
      :data="onlyTransactionalData"
      :transaction-column="transactionColumn"
      v-model="purchaseFilter"
      @submit="nextStep(steps.FILTER_SALE)"
    />

    <asset-sale
      v-if="currentStep === steps.FILTER_SALE"
      :data="onlyTransactionalData"
      :transaction-column="transactionColumn"
      v-model="saleFilter"
      @submit="nextStep(steps.TRANSACTION_DATE)"
    />

    <date-column
      v-if="currentStep === steps.TRANSACTION_DATE"
      :data="onlyTransactionalData"
      :columns="columns"
      v-model="dateColumn"
      :key="steps.TRANSACTION_DATE"
      @submit="nextStep(steps.TRANSACTION_ID)"
    />

    <choose-column
      v-if="currentStep === steps.TRANSACTION_ID"
      :data="onlyTransactionalData"
      :columns="columns"
      v-model="idColumn"
      :key="steps.TRANSACTION_ID"
      @submit="nextStep(steps.TRANSACTION_ASSET)"
    >
      <template v-slot:header>
        Choose transaction id column
      </template>

      <template v-slot:description>
        <p>
          Pick the column that is unique for every transaction record.
          This will be used to prevent duplicates if you import the same file again.
        </p>
      </template>
    </choose-column>

    <choose-column
      v-if="currentStep === steps.TRANSACTION_ASSET"
      :data="onlyTransactionalData"
      :columns="columns"
      v-model="assetIdColumn"
      :key="steps.TRANSACTION_ASSET"
      @submit="nextStep(steps.TRANSACTION_PPS)"
    >
      <template v-slot:header>
        Choose asset id column
      </template>

      <template v-slot:description>
        <p>
          Pick the column that identifies the asset that has been transacted.
          This is usually called an ISIN.
        </p>
      </template>
    </choose-column>

    <choose-column
      v-if="currentStep === steps.TRANSACTION_PPS"
      :data="onlyTransactionalData"
      :columns="columns"
      v-model="pricePerShareColumn"
      :key="steps.TRANSACTION_PPS"
      @submit="nextStep(steps.TRANSACTION_QUANTITY)"
    >
      <template v-slot:header>
        Choose price per share column
      </template>

      <template v-slot:description>
        <p>
          Pick the column that identifies the price per share of the transaction.
        </p>
      </template>
    </choose-column>

    <choose-column
      v-if="currentStep === steps.TRANSACTION_QUANTITY"
      :data="onlyTransactionalData"
      :columns="columns"
      v-model="quantityColumn"
      :key="steps.TRANSACTION_QUANTITY"
      @submit="nextStep(steps.TRANSACTION_EXCHANGE_RATE)"
    >
      <template v-slot:header>
        Choose quantity column
      </template>

      <template v-slot:description>
        <p>
          Pick the column that identifies the quantity transacted.
        </p>
      </template>
    </choose-column>

    <choose-column
      v-if="currentStep === steps.TRANSACTION_EXCHANGE_RATE"
      :data="sheetData"
      :columns="columns"
      v-model="exchangeRateColumn"
      :key="steps.TRANSACTION_EXCHANGE_RATE"
      @submit="nextStep(steps.BASE_CURRENCY)"
    >
      <template v-slot:header>
        Choose exchange rate column
      </template>

      <template v-slot:description>
        <p>
          Pick the column that identifies exchange rate of the transation.
        </p>
      </template>
    </choose-column>

    <base-currency
      v-if="currentStep === steps.BASE_CURRENCY"
      v-model="baseCurrency"
      :currencies="currencies"
      @submit="nextStep(steps.PRESET_NAME)"
      @add="nextStep(steps.PRESET_NAME)"
    />

    <save-preset
      v-if="currentStep === steps.PRESET_NAME"
      @submit="afterSavePreset"
      :preset-data="{
        transactionColumn,
        dateColumn,
        idColumn,
        assetIdColumn,
        pricePerShareColumn,
        exchangeRateColumn,
        quantityColumn,
        purchaseFilter,
        saleFilter,
        baseCurrency,
      }"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-webpack-loader-syntax
// import { camelCase } from 'lodash';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { currencyIds } from '../../../../lib/fx';
import * as steps from './stepNames';
import ChooseColumn from './ChooseColumn.vue';
import DateColumn from './DateColumn.vue';
import AssetSale from './AssetSale.vue';
import AssetPurchase from './AssetPurchase.vue';
import BaseCurrency from './BaseCurrency.vue';
import SavePreset from './SavePreset.vue';

export default {
  name: 'CreatePreset',

  components: {
    ChooseColumn,
    DateColumn,
    AssetSale,
    AssetPurchase,
    BaseCurrency,
    SavePreset,
  },

  props: {
    sheetData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentStep: steps.TRANSACTION_TYPE,
      transactionColumn: null,
      dateColumn: null,
      idColumn: null,
      assetIdColumn: null,
      pricePerShareColumn: null,
      exchangeRateColumn: null,
      quantityColumn: null,
      purchaseFilter: null,
      saleFilter: '',
      baseCurrency: null,
      presetName: '',
      currencies: currencyIds,
      steps,
    };
  },

  computed: {
    ...mapGetters({
      presets: 'importPresets/getAll',
      getPreset: 'importPresets/get',
    }),

    selectedPreset() {
      return this.getPreset(this.selectedPresetId);
    },

    assetIds() {
      return _.uniq(this.onlyTransactionalData.map((item) => item[[this.assetIdColumn]]));
    },

    onlyTransactionalData() {
      return this.sheetData.filter((item) => item[this.transactionColumn]
        .toLowerCase()
        .includes(this.purchaseFilter)
            || item[this.transactionColumn]
              .toLowerCase()
              .includes(this.saleFilter));
    },

    columns() {
      if (!this.sheetData.length) return [];
      return Object.keys(this.sheetData[0]);
    },

  },

  methods: {
    nextStep(step) {
      this.currentStep = step;
    },

    afterSavePreset(presetId) {
      this.selectedPresetId = presetId;
      this.nextStep(steps.SELECT_PRESET);
      this.$emit('input', presetId);
      this.$emit('submit');
    },
  },

};
</script>

<style lang="scss">
  .import-transactions__step-wrap {
    display: flex;
  }

  .import-transactions__step {
    display: flex;
    flex-direction: column;
    width: 30rem;
    margin-right: 3rem;
    align-items: flex-start;

    h2 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .bx--form > * {
      margin-bottom: 2rem;
    }
  }

  .preset--add-button {
    flex: 0;
    align-self: flex-end;
  }

  .import-transactions__data-preview-table {
    max-width: 30rem;
  }
</style>
