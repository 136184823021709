<template>
  <div class="import-transactions__step-wrap">
    <div
      class="import-transactions__step"
    >
      <h2>Upload transactions</h2>
      <p>Sheet should contain headers.</p>

      <cv-form @submit="submit($event)">
        <cv-file-uploader
          kind=""
          helper-text="Select the file you want to upload"
          accept=".csv,xls,.xlsx"
          :clear-on-reselect="true"
          :initial-state-uploading="false"
          :multiple="false"
          :removable="false"
          v-model="files"
          ref="fileUploader"
        />
        <cv-button
          :disabled="!canSubmit"
        >
          Next
        </cv-button>
      </cv-form>
    </div>
  </div>
</template>

<script>
import {
  CvForm,
  CvFileUploader,
  CvButton,
} from '@carbon/vue';

import Papa from 'papaparse';

export default {
  name: 'SelectFile',

  components: {
    CvForm,
    CvFileUploader,
    CvButton,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      files: [],
      selectedFileContent: null,
      errorReadingFile: null,
      data: [],
    };
  },

  watch: {
    files(files) {
      if (!files.length) return;
      const reader = new FileReader();
      this.errorReadingFile = null;
      const { file } = files[0];
      reader.addEventListener('load', () => {
        this.selectedFileContent = reader.result;
      }, false);

      if (file) {
        reader.readAsText(file);
      }
    },

    selectedFileContent(content) {
      const result = Papa.parse(content, {
        header: true,
        dynamicTyping: true,
      });

      if (result.errors.length) {
        this.errorReadingFile = result.errors[0].message;
      }

      this.data = result.data;
    },
  },

  computed: {
    canSubmit() {
      return this.data.length && !this.errorReadingFile;
    },
  },

  methods: {
    submit(event) {
      event.preventDefault();
      this.$emit('input', this.data);
      this.$emit('submit', this.data);
      return false;
    },
  },

};
</script>
